import './index.scss';
import React, { useState } from 'react';
import { Callout } from '../../../../styles';
import HTMLVideo from '../../../../components/HTMLVideo';
import { Tabs, Tab } from '../../../../components/Tabs';
import { ColumnTabContent, ColumnTabs } from '../column-tabs';
import { Small } from '../../../../styles';

const UniqueFeaturesData = [
    {
        tab: "Onshape Integration",
        content: [
            {
                title: "Convert Phi models to Onshape Part Studios",
                desc: "Send your Phi models to Onshape part Studios, live updates, export to STL or STEP.",
                video: "/unique-features/F11.mp4",
            },
            {
                title: "Import reference models and reference sketches",
                desc: "Import reference models from Onshape or STL, and sketches to use as reference.",
                video: "/unique-features/F12.mp4",
            },
            {
                title: "Import and edit Onshape designs",
                desc: "Import Onshape Part Studios, or parts of them, and edit them within Phi",
                video: "/unique-features/F13.mp4",
            },
        ],
    },
    {
      tab: "Curve-based design",
      content: [
            {
                title: "Direct and limitless curve manipulation",
                desc: "Directly and freely edit your curves to express your design intent",
                video: "/unique-features/F21.mp4",
            },
            {
                title: "Smoothness and continuity where desired",
                desc: "Add smoothness and continuity between edges and faces as you desire",
                video: "/unique-features/F22.mp4",
            },
            {
                title: "Advanced edge manipulation tools",
                desc: "Naturally manipulate and deform edges, faces adapt, interactive curvature combs",
                video: "/unique-features/F23.mp4",
            },
        ],
    },
    {
        tab: "Intuitive User Interface",
        content: [
            {
                title: "Colors, materials, wireframe, transparency",
                desc: "Colors and materials for different faces, lighting and background options, wireframe and transparent view",
                video: "/unique-features/F31.mp4",
            },
            {
                title: "Help at your fingertips, autosave in Onshape",
                desc: "Tooltips, videos, search, context-specific menu, autosave your work to Onshape document",
                video: "/unique-features/F32.mp4",
            },
            {
                title: "Freeform deformation operations",
                desc: "Freeform move, deform, inflate, deflate, scale, and rigid transformations",
                video: "/unique-features/F33.mp4",
            },
        ],
    },
    {
        tab: "Engineering Precision",
        content: [
            {
                title: "Precise and controlled moving, scaling, and positioning",
                desc: "Move and scale sets of objects through precise interfaces, create and orient linear circular, planar shapes",
                video: "/unique-features/F41.mp4",
            },
            {
                title: "Grids, snapping, coordinates, fixing",
                desc: "Display coordinates, set workspace units, grids, snapping, precision, shadows, and alignment in 3D",
                video: "/unique-features/F42.mp4",
            },
            {
                title: "Smoothness, continuity, and curvature control",
                desc: "Apply smoothness and continuity between edges and faces, create freeform shapes with precision",
                video: "/unique-features/F43.mp4",
            },
        ],
    },
    {
        tab: "Advanced Operations",
        content: [
            {
                title: "Mirroring and symmetries",
                desc: "Two different types of mirror operations that can be combined for local and global results",
                video: "/unique-features/F51.mp4",
            },
            {
                title: "Multibody support, copy, merge, snap bodies",
                desc: "Import multiple bodies, join or separate them, or copy and snap them together",
                video: "/unique-features/F52.mp4",
            },
            {
                title: "Popout, carve, bridge, dissolve",
                desc: "Volume modifications through pop-outs, bridges, carving and holes between faces",
                video: "/unique-features/F53.mp4",
            },
        ],
    },
] as const;

export default function UniqueFeatures() {
    const [active, setActive] = useState(0)

    return <section className='unique-features container'>
        <header>
            <Small as='p'>Unique Features</Small>
            <Callout as="h2">Surface Modeling Features</Callout>
        </header>

        <Tabs active={active} onActive={setActive}>
            {UniqueFeaturesData.map((feature) => (
                <Tab name={feature.tab} key={feature.tab}>
                    <ColumnTabs>
                        {feature.content.map((tab, i) => (
                            <ColumnTabContent
                                key={`ctc-${i}`}
                                title={tab.title}
                                description={tab.desc}
                            >
                                <HTMLVideo src={tab.video} width={730} height={493} />
                            </ColumnTabContent>
                        ))}
                    </ColumnTabs>
                </Tab>
            ))}
        </Tabs>
    </section>
}
