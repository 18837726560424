import React from "react"

const Icon1 = () => (
  <svg viewBox="0 0 82 58">
    <g transform="translate(-4 -17)">
      <path
        fill="none"
        stroke="#f9c32a"
        strokeLinecap="round"
        strokeWidth="2.6px"
        fillRule="evenodd"
        d="M20.6,21.9C53,22,34.5,70.5,70.5,70.5"
      />
      <circle fill="#474747" cx="5" cy="5" r="5" transform="translate(76 65)" />
      <circle fill="#474747" cx="5" cy="5" r="5" transform="translate(4 17)" />
    </g>
  </svg>
)

const Icon2 = () => (
  <svg viewBox="0 0 80.199 60">
    <g
      fill="none"
      strokeLinecap="round"
      fillRule="evenodd"
      transform="translate(-5.5 -15.5)"
    >
      <path
        stroke="#f9c32a"
        strokeWidth="2.6px"
        d="M25,24c22.75,0,24.7,41.6,44.2,41.6"
      />
      <path
        stroke="#474747"
        strokeLinejoin="round"
        strokeWidth="3px"
        d="M51.2,74C31.7,74,29.75,32.4,7,32.4L40,17c22.75,0,24.7,41.6,44.2,41.6Z"
      />
    </g>
  </svg>
)

const Icon3 = () => (
  <svg viewBox="0 0 62 62">
    <g transform="translate(-15 -14)">
      <circle
        fill="#f9c32a"
        cx="19.5"
        cy="19.5"
        r="19.5"
        transform="translate(15 14)"
      />
      <circle
        fill="none"
        stroke="#474747"
        strokeWidth="3px"
        cx="9.75"
        cy="9.75"
        r="9.75"
        transform="translate(56 55)"
      />
    </g>
  </svg>
)

const Icon4 = () => (
  <svg viewBox="0 0 74.5 63">
    <g transform="translate(-8.5 -10.5)">
      <path
        fill="none"
        stroke="#474747"
        strokeWidth="3px"
        fillRule="evenodd"
        d="M48.35,12A18.85,18.85,0,0,1,67.191,30.254,15.6,15.6,0,0,1,65.9,61.4h-38C17.986,61.231,10,52.857,10,42.55,10,32.139,18.148,23.7,28.2,23.7a17.731,17.731,0,0,1,2.626.195A18.854,18.854,0,0,1,48.35,12Z"
      />
      <circle
        fill="#f9c32a"
        cx="11.05"
        cy="11.05"
        r="11.05"
        transform="translate(34.7 51.4)"
      />
    </g>
  </svg>
)

const Icon5 = () => (
  <svg viewBox="0 0 68 55.45">
    <g fill="#474747" transform="translate(-11 -18)">
      <circle
        fill="#f9c32a"
        cx="11.05"
        cy="11.05"
        r="11.05"
        transform="translate(34.1 18)"
      />
      <circle cx="9" cy="9" r="9" transform="translate(11 55.45)" />
      <circle cx="9" cy="9" r="9" transform="translate(36 55.45)" />
      <circle cx="9" cy="9" r="9" transform="translate(61 55.45)" />
    </g>
  </svg>
)

const icons = {
  "icon-1": Icon1,
  "icon-2": Icon2,
  "icon-3": Icon3,
  "icon-4": Icon4,
  "icon-5": Icon5,
}

const Icon = ({ name }) => {
  const IIcon = icons[name]
  return (
    <div className="icon">
      <IIcon />
    </div>
  )
}

export default Icon
