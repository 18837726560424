import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { fluidRem } from "../../../../styles/functions"
import hero2023webm from "../../../../videos/hero-2023.webm"
import hero2023mp4 from "../../../../videos/hero-2023.mp4"
import onshapeLogo from "../../../../images/onshape-phi-cloud-app.png"

import "./Keyvisual.scss"
import Button from "../../../../components/Button"

const Section = styled.section`
  min-height: 100vh;
  min-height: 100dvh;
  padding-block: var(--header-height);
  padding-inline: 2.4rem;
  position: relative;
  isolation: isolate;
  display: grid;
  align-items: center;
  z-index: 10;

  @media (max-width: 768px) {
    align-items: end;
  }
`
const Video = styled.video`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: -1;
`

const Header = styled.header`
  padding-inline-start: ${fluidRem("0rem", "9rem", 425)};
`

const SectionTitle = styled.h1`
  font-size: ${fluidRem("3.2rem", "5.6rem")};
  line-height: 1;
  font-weight: ${({ theme }) => theme.fontWeights.thin};
  margin: 0;
  width: min(46rem, 100%);

  @media (max-width: 475px) {
    width: min(28rem, 100%);
  }

  strong {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
`

const Description = styled.p`
  font-size: ${fluidRem("1.4rem", "1.8rem")};
  margin-block: 1em 2em;
  width: min(32rem, 100%);

  @media (max-width: 475px) {
    width: min(21rem, 100%);
  }
`

export default function Keyvisual() {
    return <Section as="section">
        <Video
            className='hero-video'
            role="presentation"
            playsInline
            autoPlay
            controls={false}
            muted
            loop
        >
            <source src={hero2023webm} type="video/webm" />
            <source src={hero2023mp4} type="video/mp4" />
        </Video>
        <Header>
            <SectionTitle>
                Surface modeling <br />
                <strong>reinvented</strong>
            </SectionTitle>
            <Description>
                Conceptualise and design amazing 3D freeform surface models with speed and engineering precision.
            </Description>
            <Button
                href="https://app.phi3d.com/"
                variant="primary"
                activeClassName="active"
                partiallyActive={true}
                rel="noopener noreferrer"
                target="_blank"
                title='Try Phi now'
            >
                Access Phi Now
            </Button>
            <br/>
            <br/>
            <br/>
            <a href="https://appstore.onshape.com/apps/Design%20&%20Documentation/BI4NF3BZAEQXBXOERCYO6NSHJ5Z62TB6ZI472VA=/description" target="_blank"> <img src={onshapeLogo} width = "250"/></a>
        </Header>
    </Section>
}
