import React from 'react';
import { OrganicDesign } from '../../styles';
import { H1, H2, P } from '../../../../styles';

export default function OrganicDesignComponent() {
    return <OrganicDesign as="section">
        <OrganicDesign.Header>
            <H1 as={H2}>Revolutionizes organic 3D design</H1>
        </OrganicDesign.Header>

        <OrganicDesign.CardsWrapper>
            <OrganicDesign.Card>
                <P>Innovative modeling tools offer new design capabilities</P>
            </OrganicDesign.Card>
            <OrganicDesign.Card>
                <P>Intuitive approach, allows you to focus on your design ideas</P>
            </OrganicDesign.Card>
            <OrganicDesign.Card>
                <P>Based on original, state-of-the-art proprietary technologies</P>
            </OrganicDesign.Card>
            <OrganicDesign.Card>
                <P>Effortless 3D design for both novice and professional users</P>
            </OrganicDesign.Card>
        </OrganicDesign.CardsWrapper>
    </OrganicDesign>;
}
