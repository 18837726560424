import React from 'react'
import { AmazingTools } from '../../styles'
import { H1, P } from '../../../../styles'
import { chunk } from 'lodash'

const AmazingIcons = [
    "smoothen_tjunction.svg",
    "smoothen_edges_as_one.svg",
    "unsmoothen_vertex.svg",
    "smoothen_vertex.svg",
    "unsmoothen_edges.svg",
    "snap_to_body.svg",
    "curvature_comb_settings.svg",
    "merge_on_edge.svg",
    "mirror.svg",
    "invisible.svg",
    "clone_body.svg",
    "clone_edge.svg",
    "convert_ref_to_phi.svg",
    "edit_reference.svg",
    "add_edge.svg",
    "add_vertex.svg",
    "anchor.svg",
    "carve.svg",
    "delete.svg",
    "edit_sketches.svg",
    "fill_faces.svg",
    "handle.svg",
    "hole.svg",
    "import_reference.svg",
    "load_sketches.svg",
    "make_circular_exact.svg",
    "make_edge_linear.svg",
    "make_edges_linear_as_one.svg",
    "make_pair_of_faces_smooth.svg",
    "merge_faces.svg",
    "offset.svg",
    "popin.svg",
    "popout_cut.svg",
    "popout.svg",
    "rigid_move.svg",
    "scale.svg",
    "search.svg",
    "set_symmetry_plane.svg",
    "set_vertex_coordinates.svg",
    "show_reference_model.svg",
    "transparent_reference.svg",
    "smoothen_strength.svg",
    "snap_to_reference.svg",
    "splitpatch.svg",
    "symmetry.svg",
    "inside_red.svg",
    "autosave.svg",
    "settings.svg",
] as const;

export default function AmazingToolsComponent() {
    return <AmazingTools as="section">
        <AmazingTools.Header>
            <H1 as="h2">Innovative Tools</H1>
            <P margin="1.6rem 0 0 0">
                Our curved based modeling approach offers top quality surfaces,
                a more natural editing experience and real interoperability.
            </P>
        </AmazingTools.Header>

        <AmazingTools.IconsWrapper aria-hidden="true">
            {chunk(AmazingIcons, 4).map((set, i) => (
                <AmazingTools.IconsRow key={i}>
                {set.map((icon, i) => (
                    <AmazingTools.Icon key={i}>
                    <img src={`/amazing-tools/${icon}`} width="24" height="24" alt="" role="presentation" />
                    </AmazingTools.Icon>
                ))}
                </AmazingTools.IconsRow>
            ))}
        </AmazingTools.IconsWrapper>
    </AmazingTools>
}
