import React, { useCallback, useState } from "react"
import styled, { useTheme } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { Controller } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "../../../../scss/swiper.scss"
import Container from "../../../../components/Container"
import { H2, H4, theme } from "../../../../styles"
import Arrow from "../../../../images/right-chevron.inline.svg"

const TestimonialsContainer = styled(Container)`
  > div {
    padding: 0 90px;
    position: relative;
    @media (max-width: 768px) {
      padding: 0;
    }
  }
`

const TestimonialsHeader = styled.header`
  padding-top: 8rem;
  margin-bottom: 2.4rem;
`

const TestimonialsSlider = styled.div`
  position: relative;
  > svg {
    position: absolute;
    fill: ${theme.colors.brand};
    width: 19px;
    height: 30px;
    top: calc(50% - 15px);
    z-index: 5;
    cursor: pointer;
  }
  > svg:first-child {
    left: -80px;
    transform: scale(-1, 1);
  }
  > svg:last-child {
    right: -80px;
  }
  @media (max-width: 768px) {
    > svg {
      display: none;
    }
  }
`

const Testimonial = styled.div`
  background-color: #1c1c1c;
  padding: 48px;
  > q {
    margin: 0 0 20px 0;
    font-size: 18px;
    line-height: 28px;
    color: #f4f4f4;
    display: block;
  }
  > h4 {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #bfbfbf;
  }
  @media (max-width: 768px) {
    padding: 24px;
  }
`

const asideOptions = {
  renderNode: {
    [BLOCKS.HEADING_2]: (node, children) => children,
    [BLOCKS.PARAGRAPH]: (node, children) => children,
  },
}

const TestimonialCard = ({ nameAndTitle, body }) => {
  return (
    <Testimonial>
      <q>{body && renderRichText(body, asideOptions)}</q>
      <H4>{nameAndTitle}</H4>
    </Testimonial>
  )
}

const Testimonials = () => {
  const theme = useTheme()
  const { allContentfulTestimonial: testimonials } = useStaticQuery(
    graphql`
      query AllTestimonials {
        allContentfulTestimonial {
          nodes {
            contentful_id
            nameAndTitle
            body {
              raw
            }
          }
        }
      }
    `
  )
  const [slider, setSlider] = useState(null)

  const onArrowClick = useCallback(
    (isNext) => {
      if (isNext) {
        slider.slideNext()
      } else {
        slider.slidePrev()
      }
    },
    [slider]
  )

  return (
    <TestimonialsContainer>
      <div>
        <TestimonialsHeader>
          <H2 as="h3" color={theme.colors.brand}>
            Testimonials
          </H2>
        </TestimonialsHeader>
        <TestimonialsSlider>
          <Arrow onClick={() => onArrowClick(false)} />
          <Swiper
            modules={[Controller]}
            onSwiper={setSlider}
            spaceBetween={16}
            slidesPerView={1}
            loop={true}
          >
            {testimonials.nodes.map((i) => (
              <SwiperSlide key={i.contentful_id}>
                <TestimonialCard {...i} />
              </SwiperSlide>
            ))}
          </Swiper>
          <Arrow onClick={() => onArrowClick(true)} />
        </TestimonialsSlider>
      </div>
    </TestimonialsContainer>
  )
}

export default Testimonials
