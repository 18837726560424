import React, { useContext } from "react"

import { PhiStateContext } from "../../context";
import Layout from "../../layouts/default";
import SEO from "../../components/seo";

import PreFooter from "./components/PreFooter";
import Testimonials from "./components/testimonials";
import Examples from "./components/examples";
import UniqueFeatures from './components/unique-features';
import Keyvisual from "./components/keyvisual";
import FeaturesListComponent from './components/features-list';
import OrganicDesignComponent from './components/organic-design';
import AmazingToolsComponent from './components/amazing-tools';

export default function IndexPage() {
    const { earlyAccessPopup } = useContext(PhiStateContext)

    return <Layout>
        <SEO
            title="Design amazing 3D Models"
            description="Surfaces and Items 3D Modeling, Design on Cloud"
            bodyClass={`${earlyAccessPopup ? "noscroll" : ``}`}
        />
        <main>
            <Keyvisual />
            <Examples />
            <UniqueFeatures/>
            <FeaturesListComponent/>
            <OrganicDesignComponent/>
            <AmazingToolsComponent/>
            <Testimonials />
            <PreFooter />
        </main>
    </Layout>;
}
