import React, { useContext, useEffect, useRef } from "react"
import { PhiDispatchContext } from "../../../../context"
import "./PreFooter.scss"

const PreFooter = () => {
  const footerShape1Ref = useRef(null)
  const footerShape2Ref = useRef(null)
  const footerCircleRef = useRef(null)
  const dispatch = useContext(PhiDispatchContext)

  useEffect(() => {
    const onMouseMoveFooter = e => {
      let x = e.clientX / 50
      let y = e.clientY / 50
      footerShape1Ref.current.style.transform = "translateY(" + y + "px)"
      footerShape2Ref.current.style.transform = "translateY(" + y + "px)"
      footerCircleRef.current.style.transform =
        "translateX(" + x * 1.5 + "px) translateY(" + y * 1.5 + "px)"
    }

    const handleResize = () => {}

    window.addEventListener("mousemove", onMouseMoveFooter)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("mousemove", onMouseMoveFooter)
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <section className="pre-footer">
      <div className="f-shapes">
        <svg
          viewBox="0 0 2875 1379"
          preserveAspectRatio="none"
          width="100%"
          height="100%"
        >
          <defs>
            <linearGradient
              id="shape-1"
              x1="0.908"
              y1="0.897"
              x2="0.022"
              y2="0.375"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stopColor="#4d4d4d" />
              <stop offset="1" stopColor="#111111" />
            </linearGradient>
            <linearGradient
              id="shape-2"
              y1="0.5"
              x2="1"
              y2="0.5"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stopColor="#444444" />
              <stop offset="1" stopColor="#0a0a0a" />
            </linearGradient>
          </defs>

          <path
            ref={footerShape1Ref}
            fill="url(#shape-1)"
            d="M2875,369s-686.08,550.611-1474,663c-288.5,41.15-632.554-111.862-942-246C202.38,674.761,0,576,0,576v803H2875V369Z"
          />
          <circle
            ref={footerCircleRef}
            fill="#ffc328"
            cx="1775.5"
            cy="822.5"
            r="259.5"
          />
          <path
            ref={footerShape2Ref}
            fill="url(#shape-2)"
            d="M2875,39s-195.64-7-386,156c-443.83,380.052-616.26,817.35-1215,855C751.574,1082.85,0,886,0,886v493H2875V39Z"
          />
        </svg>
      </div>
    </section>
  )
}

export default PreFooter
