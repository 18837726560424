import React from 'react';
import { FeaturesList } from '../../styles';

const FeaturesListData = [
    {
        icon: "icon-1",
        title: "Curve-based\nmodeling",
        content:
            "Simply draw natural free-form curves reflecting your design intent, and Phi automatically and interactively calculates the surfaces.",
    },
    {
        icon: "icon-2",
        title: "Top quality\nsurfaces",
        content:
            "Phi generates top quality, watertight and smooth surfaces. Ease of modeling is combined with engineering quality, bridging artistic design with precise engineering.",
    },
    {
        icon: "icon-3",
        title: "Intuitive\ndesign",
        content:
            "Intuitive, easy and effortless modeling. Phi enables everyone to freely design and manipulate 3D shapes in a fraction of the time.",
    },
    {
        icon: "icon-4",
        title: "Cloud/browser\nbased",
        content:
            "Phi is fully on the cloud and runs on web browsers on any platform at interactive speeds.",
    },
    {
        icon: "icon-5",
        title: "Real\nInteroperability",
        content:
            "Offers essential interoperability with other CAD systems (and 3D printers) by supporting several standard file formats.",
    },
] as const;

export default function FeaturesListComponent() {
    return <FeaturesList>
        <FeaturesList.Wrapper>
        {FeaturesListData.map((f, i) => (
            <FeaturesList.Feature
                key={i}
                icon={f.icon}
                title={f.title}
                content={f.content}
            />
        ))}
        </FeaturesList.Wrapper>
  </FeaturesList>;
}
