import React, { useState } from "react"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { RenderedExamples } from "../../styles"
import { Callout } from "../../../../styles"
import "./index.scss"

const earbuds = [
   {
        url: '/examples/earbuds/01.jpg',
        alt: 'Phi logo from earbud designed in Phi Surface Modeler',
   } as const,
   {
        url: '/examples/earbuds/02.jpg',
        alt: 'Earbuds designed in Phi Surface Modeler',
   } as const,
   {
        url: '/examples/earbuds/03.jpg',
        alt: 'Earbuds designed in Phi Surface Modeler',
   } as const,
] as const;

const decanter = [
    {
        url: '/examples/decanter/01.jpg',
        alt: 'Freeform bottle designed in Phi Surface Modeler',
    } as const,
    {
        url: '/examples/decanter/02.jpg',
        alt: 'Fancy bottle designed in Phi Surface Modeler',
    } as const,
    {
        url: '/examples/decanter/03.jpg',
        alt: 'Brandy Decanter designed in Phi Surface Modeler',
    } as const,
    {
        url: '/examples/decanter/04.jpg',
        alt: 'Freeform bottle designed in Phi Surface Modeler',
    } as const,
    {
        url: '/examples/decanter/05.jpg',
        alt: 'Fancy bottle designed in Phi Surface Modeler',
    } as const,
    {
        url: '/examples/decanter/06.jpg',
        alt: 'Brandy Decanter designed in Phi Surface Modeler',
    } as const,
    {
        url: '/examples/decanter/07.jpg',
        alt: 'Freeform bottle designed in Phi Surface Modeler and Onshape',
    } as const,
] as const;

const saddle = [
    {
        url: '/examples/bicycle-saddle/01.jpg',
        alt: 'Bicycle saddle designed in Phi Surface Modeler',
    } as const,
    {
        url: '/examples/bicycle-saddle/02.jpg',
        alt: 'Bicycle saddle designed in Phi Surface Modeler',
    } as const,
    {
        url: '/examples/bicycle-saddle/03.jpg',
        alt: 'Bicycle saddle designed in Phi Surface Modeler',
    } as const,
    {
        url: '/examples/bicycle-saddle/04.jpg',
        alt: 'Bicycle saddle designed in Phi Surface Modeler',
    } as const,
    {
        url: '/examples/bicycle-saddle/05.jpg',
        alt: 'Bicycle saddle designed in Phi Surface Modeler and Onshape',
    } as const,
    {
        url: '/examples/bicycle-saddle/06.jpg',
        alt: 'Bicycle saddle designed in Phi Surface Modeler',
    } as const,
    {
        url: '/examples/bicycle-saddle/07.jpg',
        alt: 'Bicycle saddle designed in Phi Surface Modeler',
    } as const,
    {
        url: '/examples/bicycle-saddle/08.jpg',
        alt: 'Bicycle saddle designed in Phi Surface Modeler and Onshape',
    } as const,
] as const;

const gallery = [
    {
        thumbnail: earbuds[0],
        images: earbuds,
    } as const,
    {
        thumbnail: decanter[0],
        images: decanter,
    } as const,
    {
        thumbnail: saddle[0],
        images: saddle,
    } as const,
] as const;

const Examples = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)
  const [allExamples] = useState(() => [...earbuds, ...decanter, ...saddle] as const)

  function thumbnail_clicked(url: string) {
    setPhotoIndex(allExamples.findIndex(x => x.url === url));
    setIsOpen(true);
  }

  return (
    <>
      <RenderedExamples>
        <Callout as="h2">Rendered Design Examples</Callout>
        <RenderedExamples.Thumbnails>
          {gallery.map((i, index) => (
            <RenderedExamples.ThumbnailButton
              key={index}
              onClick={() => thumbnail_clicked(i.thumbnail.url)}
            >
              <RenderedExamples.ThumbnailImage
                src={i.thumbnail.url}
                alt={i.thumbnail.alt}
                width="1920"
                height="1648"
                role="presentation"
              />
            </RenderedExamples.ThumbnailButton>
          ))}
        </RenderedExamples.Thumbnails>
      </RenderedExamples>

      {isOpen && (
        // @ts-ignore
        <Lightbox
          mainSrc={allExamples[photoIndex].url}
          nextSrc={allExamples[(photoIndex + 1) % allExamples.length].url}
          prevSrc={allExamples[(photoIndex + allExamples.length - 1) % allExamples.length].url}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + allExamples.length - 1) % allExamples.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % allExamples.length)
          }
          imageCaption={`Final Design and Rendering: Vince Haley - SkillCoach`}
          enableZoom={false}
          imagePadding={24}
          wrapperClassName={"rendered-examples-grid"}
        />
      )}
    </>
  )
}

export default Examples
