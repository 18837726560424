import { transitions } from "polished"
import React, { useCallback, useState } from "react"
import styled from "styled-components"
import { H4, Small, P } from "../../../../styles"
import { ar } from "../../../../styles/functions"

const SColumnTabs = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  align-items: center;
  gap: 6.4rem;
`
const Menu = styled.ul``

const SMenuItem = styled.li`
  cursor: pointer;
  position: relative;
  padding: 0 0 0 2.4rem;

  + li {
    margin-top: 3.2rem;
  }

  &:after,
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0.2rem;
  }

  &:before {
    background-color: ${({ theme }) => theme.colors.greyDark};
  }

  &:after {
    transform-origin: top center;
    transform: ${({ isSelected }) => (isSelected ? `scaleY(1)` : `scaleY(0)`)};
    background-color: ${({ theme }) => theme.colors.brand};
    ${transitions(["transform"], ".2s ease")};
  }

  ${H4} {
    color: ${({ theme }) => theme.colors.greyLight};
  }

  ${Small} {
    color: ${({ theme }) => theme.colors.greyLight};
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    `
    ${H4} {
      color: ${theme.colors.white};
    }
  `}
`

export const ColumnTabContent = styled.div`
  /* ${ar(730, 493, true)}; */
`

export const ColumnTabs = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState(0)

  return (
    <SColumnTabs>
      {children[selectedTab]}
      <Menu>
        {children.map((tab, i) => (
          <MenuItem
            key={`button-${i}`}
            title={tab.props.title}
            description={tab.props.description}
            index={i}
            setSelectedTab={setSelectedTab}
            isSelected={selectedTab === i}
          />
        ))}
      </Menu>
    </SColumnTabs>
  )
}

const MenuItem = ({
  title,
  description,
  index,
  setSelectedTab,
  isSelected,
}) => {
  // const theme = useTheme()
  const change_tab = useCallback(() => {
    setSelectedTab(index)
  }, [setSelectedTab, index])

  return (
    <SMenuItem
      onClick={change_tab}
      onMouseOver={change_tab}
      isSelected={isSelected}
      role="button"
      tabIndex={0}
    >
      <H4 as="h3">{title}</H4>
      <Small margin="0.8rem 0 0 0" as={P}>
        {description}
      </Small>
    </SMenuItem>
  )
}
