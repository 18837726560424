import React from "react"
import { lighten, transitions } from "polished"
import styled from "styled-components"
import Container from "../../components/Container"
import Icon from "../../components/FeaturesList/Feature/Icon/Icon"
import { ar, _size } from "../../styles/functions"
import { H3, P } from "../../styles/typography"

export const RenderedExamples = styled(Container)`
  padding-block: calc(var(--header-height) + 5rem) 0;
`

const RenderedExamplesThumbnails = styled.div`
  margin-top: 6.4rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3.2rem;

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr;
    gap: 2.4rem;
    padding: 0 3.2rem;
  }
`

const RenderedExamplesThumbnailButton = styled.button`
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 0;
  margin: 0;
  background: none;

  &::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: calc((10 / 16) * 100%);
  }
`

const RenderedExamplesThumbnailImage = styled.img`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`

RenderedExamples.Thumbnails = RenderedExamplesThumbnails
RenderedExamples.ThumbnailButton = RenderedExamplesThumbnailButton
RenderedExamples.ThumbnailImage = RenderedExamplesThumbnailImage

export const AmazingTools = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  /* min-height: 100vh; */
  padding-top: 8rem;
  padding-bottom: 8rem;
  overflow-x: hidden;

  a {
    align-self: center;
  }
`

const AmazingToolsHeader = styled.header`
  text-align: center;
  justify-self: center;
  max-width: 50ch;
  margin: 0 auto;

  p {
    color: ${({ theme }) => theme.colors.greyLight};
  }
`

const AmazingToolsIconWrapper = styled.section`
  margin: 6rem auto;
  max-width: 80rem;
  padding: 0 2rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  justify-content: center;
  justify-items: center;
  gap: 2.4rem;

  /* &:hover {
    button {
      opacity: 0.25;
    }
  } */

  > div {
    > button {
      &:nth-child(odd) {
        transform: translateX(-1rem);
      }
      &:nth-child(even) {
        transform: translateX(1rem);
      }
    }
  }


`
const AmazingToolsIconsRow = styled.div`
  display: grid;
  gap: 2.4rem;
`
const AmazingToolsIcon = styled.button`
  padding: 0.4rem;
  border-radius: 0.4rem;
  background-color: ${({ theme }) => theme.colors.greyDark};
  ${transitions(["background-color", "transform", "opacity"], ".2s ease")};
  user-select: none;
  ${_size("4rem")};
  cursor: default;

  &:hover {
    /* opacity: 1 !important; */
    background-color: ${({ theme }) => lighten(0.08, theme.colors.greyDark)};
  }

  img {
    ${_size("3.2rem")};
  }
`

AmazingTools.Header = AmazingToolsHeader
AmazingTools.IconsWrapper = AmazingToolsIconWrapper
AmazingTools.IconsRow = AmazingToolsIconsRow
AmazingTools.Icon = AmazingToolsIcon

export const FeaturesList = styled.section`
  padding-top: 8rem;
  padding-bottom: 8rem;
`

const FeaturesListWrapper = styled(Container)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 4rem;

  @media (max-width: 768px) {
    gap: 2.4rem;
  }
  @media (max-width: 368px) {
    grid-template-columns: 1fr;
  }
`

const FeatureListCard = styled.article`
  padding: 5.6rem;

  @media (max-width: 768px) {
    padding: 3.2rem;
  }

  @media (max-width: 560px) {
    padding: 0;
  }

  .icon {
    ${_size("8rem")};
  }

  ${H3} {
    margin: 2.4rem 0;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
  ${P} {
    color: ${({ theme }) => theme.colors.greyLight};
  }
`
const Feature = ({ icon, title, content }) => {
  return (
    <FeatureListCard>
      <Icon name={icon} />
      <H3>{title}</H3>
      <P>{content}</P>
    </FeatureListCard>
  )
}

FeaturesList.Wrapper = FeaturesListWrapper
FeaturesList.Feature = Feature

export const OrganicDesign = styled(Container)`
  min-height: 40rem;
  background: url("/shapes.svg");
  background-position: 50%;
  background-size: 3800px 100%;
  padding-top: 8rem;
  padding-bottom: 8rem;
`

const OrganicDesignHeader = styled.header`
  margin-bottom: 4.8rem;
`
const OrganicDesignCardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3.2rem;
`
const OrganicDesignCard = styled.article`
  background-color: ${({ theme }) => theme.colors.grey};
  position: relative;
  ${transitions(["background-color"], ".2s ease")};

  @media (min-width: 580px) {
    ${ar(1, 1, true)};
  }

  &:hover {
    background-color: ${({ theme }) => lighten(0.05, `${theme.colors.grey}`)};

    ${P} {
      &:before {
        width: 100%;
      }
    }
  }

  ${P} {
    padding: 2.4rem 2.4rem 4.8rem;
    font-size: 2rem;

    @media (min-width: 580px) {
      padding: 2.4rem;
    }

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      will-change: width;
      transform-origin: top left;
      background-color: ${({ theme }) => theme.colors.brand};
      ${_size("2.4rem")};
      ${transitions(["width"], ".5s ease")};
    }
  }
`
OrganicDesign.Header = OrganicDesignHeader
OrganicDesign.CardsWrapper = OrganicDesignCardsWrapper
OrganicDesign.Card = OrganicDesignCard
