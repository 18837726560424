import './index.scss';
import React, { useEffect } from "react"

const HTMLVideo = ({ src, width, height }) => {
    useEffect(() => {
        if (typeof window !== "undefined" && typeof document !== "undefined") {
            const Plyr = require("plyr")
            Array.from(document.querySelectorAll(".html-video")).map((p) => {
                new Plyr(p)
            })
        }
    }, [src]);

    return <video
        src={src}
        width={width}
        height={height}
        // @ts-ignore
        type="video/mp4"
        className="html-video"
        playsInline
        autoPlay
        controls={false}
        muted
        loop
    />
}

export default HTMLVideo
